// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDhB5cQjY8j2ngSaFVyUjOLJ8G-zS4n480",
    authDomain: "vinayak-foods.firebaseapp.com",
    projectId: "vinayak-foods",
    storageBucket: "vinayak-foods.appspot.com",
    messagingSenderId: "462646584085",
    appId: "1:462646584085:web:1b78ee7f87bdf1faf93f1c",
    measurementId: "G-CMPMLLZPQY",
    databaseURL: "https://vinayak-foods-default-rtdb.asia-southeast1.firebasedatabase.app/"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
// const firebaseAnalytics = getAnalytics(firebaseApp);

export default firebaseApp
