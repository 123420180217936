import React from "react";
import MainLogo from '../../images/main_logo.png'


function BottomNavBar() {
    return (
        <div className="flex flex-row items-center gap-2 justify-center w-full px-10 py-5 bg-black text-white">
            <div className="flex flex-row gap-5 items-center">
                <img src={MainLogo} alt="logo" style={{ width: 30, height: 30 }} />
                <div className="font-medium text-xs">Vinayak Foods & Catering {' '}</div>
            </div>
            <div>
                |
            </div>
            <div className="font-medium text-xs">
                All Rights Reserved
            </div>
            <div>
                |
            </div>
            <div className="font-medium text-xs">
                Contact Us
            </div>
        </div>
    )

}

export default BottomNavBar