import React from "react";
import BGImg from "../../images/top_bg.png";
import BottomNavBar from "../BottomNavBar/BottomNavBar";
import LeadForm from "../LeadForm/LeadForm";
import Offerings from "../Offerings/Offerings";
import TopNavBar from "../TopNavBar/TopNavBar";

function HomePage() {
  return (
    <div className="flex flex-col pb-0">
      <div
        className="sticky top-0 z-20 fixed shadow-xl"
        style={{ background: "white" }}
      >
        <TopNavBar />
      </div>
      <div
        className="flex flex-col relative"
        style={{ background: `url(${BGImg})`, height: 400 }}
      >
        <div
          className="flex flex-col items-center relative inset-x-0"
          style={{
            background: "rgba(0,0,0,0.7)",
            height: "100%",
            width: "100%",
            placeContent: "center",
          }}
        >
          <div
            className="md:text-4xl text-2xl text-center text-white"
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Vinayak Foods & Catering
          </div>
          <div
            className="text-normal text-white"
            style={{
              fontFamily: "Tangerine, cursive",
              fontSize: 24,
              letterSpacing: 1,
            }}
          >
            Bringing flavour to your doorstep
          </div>
          <div className="flex flex-row gap-5 mt-5">
            <a href="tel:+91-8789292073" className="custom-btn">
              Call Now
            </a>
            <button
              className="custom-btn"
              onClick={() => {
                window.location.href = "#lead-form";
              }}
            >
              Get Quotation
            </button>
          </div>
        </div>
      </div>
      <Offerings />
      <div className="w-full" id="lead-form">
        <LeadForm />
      </div>
      <BottomNavBar />
    </div>
  );
}

export default HomePage;
