import React from "react";
import MainLogo from "../../images/main_logo.png";

function TopNavBar() {
  return (
    <div className="flex flex-row items-center justify-between w-full md:px-10 px-4 py-5">
      <div className="flex flex-row gap-5 items-center">
        <img src={MainLogo} alt="logo" style={{ width: 40, height: 40 }} />
        <div className="font-medium md:text-xl text-sm">
          Vinayak Foods & Catering
        </div>
      </div>
      <div className="flex flex-row md:gap-5 md:flex hidden items-center md:text-lg text-xs">
        <a href="/">Home</a>
        <a href="#services">Services</a>
      </div>
    </div>
  );
}

export default TopNavBar;
