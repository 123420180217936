import { Helmet } from "react-helmet";
import HomePage from "./components/HomePage/HomePage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <Helmet>
        <title>
          Best Catering Services in Patna | Vinayak Foods & Catering
        </title>
        <meta
          name="description"
          content="Experience top-notch catering services in Patna with Vinayak Foods & Catering. We offer a wide range of delicious menus for weddings, parties, and corporate events."
        />
        <meta
          name="keywords"
          content="affordable caterers, catering services, Patna, wedding catering, party catering, grih pravesh catering, best caterers in patna, corporate events, delicious menus"
        />
        <meta name="geo.placename" content="Patna" />
        <meta name="geo.region" content="IN-BR" />
        <meta name="geo.position" content="25.5941;85.1376" />
        <meta name="ICBM" content="25.5941, 85.1376" />
        <link rel="canonical" href="https://www.vinayakfoodsncatering.in" />
      </Helmet>
      <HomePage />
      <ToastContainer />
    </>
  );
}

export default App;
