import React, { useState } from "react";
import "./LeadForm.css"; // Import CSS file for styling
import firebaseApp from "../../firebase";
import { getDatabase, ref, set } from "firebase/database";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LeadForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    eventType: "",
    eventDate: "",
    numberOfPeople: "",
    budgetPerPerson: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: false,
    phone: false,
    email: false,
    eventType: false,
    eventDate: false,
    numberOfPeople: false,
    budgetPerPerson: false,
    message: false,
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormErrors({ ...formErrors, [e.target.name]: false });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form fields
    let hasErrors = false;
    const newFormErrors = {};

    for (const field in formData) {
      if (formData[field] === "") {
        newFormErrors[field] = true;
        hasErrors = true;
      }
    }

    if (hasErrors) {
      setFormErrors(newFormErrors);
      return;
    }

    // Handle form submission logic here
    console.log(formData);

    const db = getDatabase();
    set(ref(db, "users/" + new Date().getTime()), formData);

    toast.success(
      "We have received your request! We will connect with you shortly with a quotation",
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    );

    // Reset form fields
    setFormData({
      name: "",
      phone: "",
      email: "",
      eventType: "",
      eventDate: "",
      numberOfPeople: "",
      budgetPerPerson: "",
      message: "",
    });
    setFormErrors({
      name: false,
      phone: false,
      email: false,
      eventType: false,
      eventDate: false,
      numberOfPeople: false,
      budgetPerPerson: false,
      message: false,
    });
  };

  return (
    <div className="flex flex-col items-center gap-5 w-full p-5 top-container">
      <div className="font-bold text-2xl">Get a quote for your event</div>
      <form onSubmit={handleSubmit} className="form-container md:w-1/2 w-full">
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {formErrors.name && (
          <span className="error-message">Name is required</span>
        )}

        <label htmlFor="phone">Phone:</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        {formErrors.phone && (
          <span className="error-message">Phone is required</span>
        )}

        <label htmlFor="email">Email:</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        {formErrors.email && (
          <span className="error-message">Email is required</span>
        )}

        <label htmlFor="eventType">Event Type:</label>
        <select
          name="eventType"
          value={formData.eventType}
          onChange={handleChange}
        >
          <option value="">Select event type</option>
          <option value="Wedding">Wedding</option>
          <option value="Birthday">Birthday</option>
          <option value="Grih Pravesh">Grih Pravesh</option>
          <option value="Corporate">Corporate</option>
          <option value="Other">Other</option>
        </select>
        {formErrors.eventType && (
          <span className="error-message">Event Type is required</span>
        )}

        <label htmlFor="eventDate">Date of Event:</label>
        <input
          type="date"
          name="eventDate"
          value={formData.eventDate}
          onChange={handleChange}
        />
        {formErrors.eventDate && (
          <span className="error-message">Date of Event is required</span>
        )}

        <label htmlFor="numberOfPeople">Number of People:</label>
        <input
          type="number"
          name="numberOfPeople"
          value={formData.numberOfPeople}
          onChange={handleChange}
        />
        {formErrors.numberOfPeople && (
          <span className="error-message">Number of People is required</span>
        )}

        <label htmlFor="budgetPerPerson">Budget per Person:</label>
        <select
          name="budgetPerPerson"
          value={formData.budgetPerPerson}
          onChange={handleChange}
        >
          <option value="">Select budget per person</option>
          <option value="Less than 300">Less than 300</option>
          <option value="300 - 500">300 - 500</option>
          <option value="More than 500">More than 500</option>
        </select>
        {formErrors.budgetPerPerson && (
          <span className="error-message">Budget per Person is required</span>
        )}

        <label htmlFor="message">Message:</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          rows="4"
        />
        {formErrors.message && (
          <span className="error-message">Message is required</span>
        )}

        <button type="submit" className="submit-button">
          Submit
        </button>
      </form>
    </div>
  );
};

export default LeadForm;
