import React from "react";
import WeddingImage from '../../images/wedding.jpg'
import CorporateImage from '../../images/corporate.jpg'
import EventImage from '../../images/events.jpg'
import BabyShowerImage from '../../images/babyshower.jpg'
import GrihPraveshImage from '../../images/grihpravesh.webp'
import OtherEventImage from '../../images/otherevent.webp'

function Offerings() {
    return (
        <div id="services" className="flex flex-col items-center bg-white p-10">
            <div className="text-3xl mb-3">
                What we offer
            </div>
            <div className="text-xl mb-5">
                We offer onsite and offsite catering for any of your special events like
            </div>
            <div className="flex md:flex-row md:flex-wrap flex-col items-center justify-between w-3/4 gap-10 text-lg" >
                <div className="flex flex-col relative" style={{ background: `url(${WeddingImage})`, height: 300, width: 300, borderRadius: 8 }}>
                    <div className="flex flex-col items-center relative z-10 inset-x-0 text-white" style={{ background: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', placeContent: 'center', borderRadius: 8 }}>
                        Wedding Catering
                    </div>
                </div>
                <div className="flex flex-col relative" style={{ background: `url(${GrihPraveshImage})`, height: 300, width: 300, borderRadius: 8 }}>
                    <div className="flex flex-col items-center relative z-10 inset-x-0 text-white" style={{ background: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', placeContent: 'center', borderRadius: 8 }}>
                        Grih Pravesh
                    </div>
                </div>
                <div className="flex flex-col relative" style={{ background: `url(${EventImage})`, height: 300, width: 300, borderRadius: 8 }}>
                    <div className="flex flex-col items-center relative z-10 inset-x-0 text-white" style={{ background: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', placeContent: 'center', borderRadius: 8 }}>
                        Birthday Parties
                    </div>
                </div>
                <div className="flex flex-col relative" style={{ background: `url(${CorporateImage})`, height: 300, width: 300, borderRadius: 8 }}>
                    <div className="flex flex-col items-center relative z-10 inset-x-0 text-white" style={{ background: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', placeContent: 'center', borderRadius: 8 }}>
                        Corporate Parties
                    </div>
                </div>
                <div className="flex flex-col relative" style={{ background: `url(${BabyShowerImage})`, height: 300, width: 300, borderRadius: 8 }}>
                    <div className="flex flex-col items-center relative z-10 inset-x-0 text-white" style={{ background: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', placeContent: 'center', borderRadius: 8 }}>
                        Baby Shower
                    </div>
                </div>
                <div className="flex flex-col relative" style={{ background: `url(${OtherEventImage})`, height: 300, width: 300, borderRadius: 8 }}>
                    <div className="flex flex-col items-center relative z-10 inset-x-0 text-white" style={{ background: 'rgba(0,0,0,0.5)', height: '100%', width: '100%', placeContent: 'center', borderRadius: 8 }}>
                        Any Other Event
                    </div>
                </div>
            </div>

        </div >
    )

}

export default Offerings